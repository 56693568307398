import {StyleExtractor} from "@kubric/utils";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import React from "react";

import {useAppLogin} from "../../../hooks/useAppLogin";
import {useMediaQuery} from "../../../hooks/useMediaQuery";
import actions from "../../../lib/actions";
import {GoldenKitty} from "../../icons/GoldenKitty";
import {ShopifyReviewsIcon} from "../../icons/ShopifyReview";
import EmailCTA from "../EmailCTA";
import * as styles from "./styles.module.scss";

export default function MMCta({
  theme,
  footer = true,
  showEmail = false,
  hideEmailInMobile = false,
  cta = "Get the App",
  emailResponse = "🙌🏻 Thanks! We got you.",
  mobileRedirectUrl = "",
  id,
  action = "mmGetStartedWithASRedirect",
  tags,
  href,
  v3,
  isBottomCTA,
  isHeroCTA,
  emailPlaceholder,
  auditCTAText = [],
  auditCTAURL = null,
  ...props
}) {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const styler = new StyleExtractor(styles, theme);
  const appLogin = useAppLogin();
  return (
    <div
      className={styler.get(["container", v3 && "v3", isHeroCTA && "heroCTA"])}
      onClick={() => {
        const tracker = window.ga.getAll()[0];
        window.dataLayer.push({userId: tracker.get("clientId")});
      }}
    >
      {(isMobile && !hideEmailInMobile) || showEmail ? (
        <EmailCTA
          propOnClick={(data) => {
            return actions.mmGetStarted(data).finally(() => {
              setTimeout(() => {
                window.location.href = isMobile ? mobileRedirectUrl : href;
              }, 2000);
            });
          }}
          data={{
            cta,
            bgColor: "var(--blue)",
            textColor: "white",
            response: emailResponse,
            placeholder: emailPlaceholder || "Enter Your Email",
            borderColor: "var(--blue)",
          }}
          overrideTags
          tags={tags || ["mobile lead"]}
          theme={{
            container: styles.ctaContainer,
          }}
          id={id}
          {...props}
        />
      ) : (
        <OutboundLink
          {...(href ? {href} : {onClick: appLogin})}
          referrerPolicy='no-referrer'
          target='_blank'
          className={styler.get([
            "cta",
            v3 && "v3",
            isBottomCTA && "bottomCTA",
            isHeroCTA && "heroCTA",
          ])}
          rel='noopener noreferrer'
          id={id}
        >
          {cta}
        </OutboundLink>
      )}
      {!isMobile && (
        <AuditPageCTA auditCTAText={auditCTAText} auditCTAURL={auditCTAURL} />
      )}
      {footer && (
        <MMReviewPanel
          theme={theme}
          showEmail={showEmail}
          v3={v3}
          isHeroCTA={isHeroCTA}
        />
      )}
    </div>
  );
}

export const MMReviewPanel = ({
  theme,
  showEmail,
  v3,
  isHeroCTA,
  showHighlighted = false,
}) => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const styler = new StyleExtractor(styles, theme);
  return (
    <div
      className={styler.get(
        ["mmCtaFooter", v3 && "v3", isHeroCTA && "heroCTA"],
        "flex items-center justify-center gap-3 md:gap-5"
      )}
    >
      {!isMobile && (
        <>
          <div
            className={styler.get([
              "shopCommAwardsDiv",
              showHighlighted && "shopCommAwardsDivHighlighted",
            ])}
          >
            <>
              <span>
                <b>Shopify</b> 20
              </span>
              <span>
                <b>Commerce</b>
              </span>
              <span>
                <b>Awards</b> 20
              </span>
            </>
          </div>
          <div className={styler.get("divider")} />
        </>
      )}
      <GoldenKitty
        className={styler.get(["phuntImage"])}
        hovered={(isMobile && v3) || showHighlighted}
      />
      <div className={styler.get("divider")} />
      <a
        href='https://www.forbes.com/sites/davidprosser/2022/10/27/mason-urges-online-retailers-to-take-back-their-independence/?sh=604b48a21619'
        target='_blank'
        rel='noreferrer'
      >
        <img
          className={styler.get(["forbes"])}
          src='https://media.kubric.io/api/assetlib/68a14c44-9e17-420c-85df-a98e48ccd1a6.png'
          alt='Forbes'
        />
      </a>
      <div className={styler.get("divider")} />
      <a
        href='https://techcrunch.com/2022/10/27/accel-backs-startup-offering-amazon-grade-commerce-engine-to-online-sellers-around-the-world/'
        target='_blank'
        rel='noreferrer'
      >
        <img
          className={styler.get(["techCrunch"])}
          src='https://media.kubric.io/api/assetlib/6e20ba7e-0cc4-48cb-a98e-b708b28197dc.png'
          alt='TechCrunch'
        />
      </a>
    </div>
  );
};

export const AuditPageCTA = ({
  auditCTAText = [],
  auditCTAURL = "",
  isMobile = false,
  mobileCTAText = "",
  mobileCTAUrl,
}) => {
  const styler = new StyleExtractor(styles);

  if (isMobile) {
    return (
      <a
        href={mobileCTAUrl}
        target='_blank'
        className={styler.get(["mobileAuditCTA"])}
        rel='noreferrer'
      >
        {mobileCTAText}
      </a>
    );
  }
  return (
    <>
      {auditCTAText?.length > 0 && (
        <span className={styler.get(["auditCTAContainer"])}>
          {auditCTAText[0].children.map((textBlock) => {
            if (textBlock.marks.includes("strong")) {
              return (
                <a
                  href={auditCTAURL}
                  target='_blank'
                  className={styler.get(["highlightedAuditCTA"])}
                  rel='noreferrer'
                  id='book-store-audit'
                >
                  {textBlock.text}
                </a>
              );
            }
            return (
              <span className={styler.get(["auditCTAText"])}>
                {textBlock.text}
              </span>
            );
          })}
        </span>
      )}
    </>
  );
};
