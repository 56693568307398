/* eslint-disable eqeqeq */
import {StyleExtractor} from "@kubric/utils";
import {Link} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import React, {useState} from "react";
import BookOpen from "react-feather/dist/icons/book-open";
import ChevronDown from "react-feather/dist/icons/chevron-down";
import ChevronUp from "react-feather/dist/icons/chevron-up";
import Video from "react-feather/dist/icons/video";

import HeaderArrow from "../../assets/headerArrow.svg";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import SanityRichText from "../commons/SanityRichText";
import {ArrowCursor} from "../icons/ArrowCursor";
import {Calculator} from "../icons/Calculator";
import * as styles from "./styles.module.scss";

export const LinkComponent = ({link = "", ...props}) => {
  const isExternalLink = link.startsWith("http");

  return isExternalLink ? (
    <OutboundLink {...props} href={link} />
  ) : (
    <Link {...props} to={link} />
  );
};

const layoutMap = {
  2: "double",
  1: "single",
};

const FeedIcon = {
  read: <BookOpen size={16} />,
  watch: <Video size={16} />,
  play: <Calculator size={16} />,
};

export const MenuItems = ({
  menuItems,
  showIntercom,
  noHeader,
  demoSection = {},
  darkMode = false,
}) => {
  const styler = new StyleExtractor(styles);
  const [dropdownState, setDropdownState] = useState(-1);
  const isMobile = useMediaQuery("(max-width: 900px)");

  const {navText = "", navUrl = ""} = demoSection;

  const intercomOption = !showIntercom
    ? []
    : [
        {
          name: "Talk to us",
          link: "#openIntercom",
        },
      ];

  return (
    <div className={styler.get(["menuContainer", noHeader && "hideHeader"])}>
      {menuItems
        .concat(intercomOption)
        .map(({name, link, hasSubMenu, ...rest}, i) => {
          if (!hasSubMenu) {
            return (
              <div key={name} className={styler.get("menuItem")}>
                <LinkComponent
                  link={link}
                  className={`${styler.get([
                    "menuLink",
                    "noSubMenu",
                    darkMode && "darkModeText",
                  ])} ${darkMode ? "darkModeText" : "menuLinkTxt"}`}
                >
                  {name}
                </LinkComponent>
              </div>
            );
          }

          const {layout, feed, entries, heading, desc, bottomLinks} = rest;
          const shouldShowDemoSection =
            !isMobile && navText && navUrl && i === 0;
          return (
            <div key={name} className={styler.get("menuItem")}>
              <div
                onClick={() => {
                  if (isMobile) {
                    dropdownState === i
                      ? setDropdownState(-1)
                      : setDropdownState(i);
                  }
                }}
                onMouseOver={() => {
                  if (!isMobile) {
                    !document.body.classList.contains("dropOverlayed") &&
                      document.body.classList.add("dropOverlayed");
                  }
                }}
                onMouseOut={() => {
                  !isMobile &&
                    document.body.classList.contains("dropOverlayed") &&
                    document.body.classList.remove("dropOverlayed");
                }}
                className={styler.get("menuLink")}
              >
                <div className={styler.get("menuLinkTxtWArrows")}>
                  <span
                    className={`${styler.get([
                      darkMode ? "darkModeText" : "menuLinkTxt",
                    ])} ${darkMode ? "darkModeText" : "menuLinkTxt"}`}
                  >
                    {name}
                  </span>
                  {!isMobile && (
                    <HeaderArrow
                      className={styler.get(
                        "desktopDropArrow",
                        "desktopDropArrow"
                      )}
                    />
                  )}
                  {isMobile &&
                    (dropdownState === i ? (
                      <ChevronUp color={darkMode ? "white" : "#394157"} />
                    ) : (
                      <ChevronDown color={darkMode ? "white" : "#394157"} />
                    ))}
                </div>
                {(isMobile ? dropdownState === i : true) && (
                  <div
                    className={styler.get([
                      "dropdownContainer",
                      i === 0
                        ? "first"
                        : i === menuItems.length - 1
                        ? "last"
                        : "mid",
                    ])}
                  >
                    <div
                      className={styler.get([
                        "dropdown",
                        darkMode && "darkMode",
                        layoutMap[layout],
                        !feed?.length && "singleColumnView",
                        shouldShowDemoSection && "alternateDesign",
                        i < menuItems.length - 1 ? "leftDrop" : "rightDrop",
                      ])}
                    >
                      {layout == 2 ? (
                        <>
                          <div
                            className={styler.get("left")}
                            style={{
                              ...(feed.length ? {} : {gridColumn: "1/4"}),
                            }}
                          >
                            <h3 className={styler.get("dropHeading")}>
                              {heading}
                            </h3>
                            <p className={styler.get("dropDescription")}>
                              {desc}
                            </p>
                            <div className={styler.get("entries")}>
                              {entries.map(
                                ({
                                  alt,
                                  heading,
                                  icon,
                                  link,
                                  desc,
                                  showNewTag,
                                }) => {
                                  return (
                                    <OutboundLink
                                      className={styler.get("entry")}
                                      href={link}
                                    >
                                      <GatsbyImage
                                        alt={alt || ""}
                                        image={icon.asset.gatsbyImageData}
                                        className={styler.get("entryIcon")}
                                      />
                                      <div
                                        className={styler.get("entryDetails")}
                                      >
                                        <h4
                                          className={styler.get("entryHeading")}
                                        >
                                          {heading}
                                          {showNewTag && (
                                            <span
                                              className={styler.get(
                                                "entryNewTag"
                                              )}
                                            >
                                              NEW
                                            </span>
                                          )}
                                        </h4>
                                        <p
                                          className={styler.get(
                                            "entryDescription"
                                          )}
                                        >
                                          {desc}
                                        </p>
                                      </div>
                                    </OutboundLink>
                                  );
                                }
                              )}
                            </div>
                            {/* these were removed, kept for time when needed */}
                            {/* {bottomLinks.length ? (
                            <div className={styler.get("bottomLinks")}>
                              {bottomLinks.map(({link, name}) => (
                                <LinkComponent
                                  link={link}
                                  className={styler.get("bottomLink")}
                                >
                                  {name}
                                </LinkComponent>
                              ))}
                            </div>
                          ) : (
                            <span />
                          )} */}
                          </div>
                          {feed.length > 0 && (
                            <div className={styler.get("feed")}>
                              {feed.map(
                                ({alt, heading, icon, link, cta, _rawDesc}) => (
                                  <OutboundLink
                                    className={styler.get("feedCard")}
                                    href={link}
                                  >
                                    <div className={styler.get("top")}>
                                      <p className={styler.get("tag")}>
                                        {FeedIcon[cta.toLowerCase()]}
                                        <span className={styler.get("tagTxt")}>
                                          {cta}
                                        </span>
                                      </p>
                                      <GatsbyImage
                                        alt={alt || ""}
                                        image={icon.asset.gatsbyImageData}
                                        className={styler.get("feedIcon")}
                                      />
                                    </div>
                                    <div className={styler.get("feedDetails")}>
                                      <h4 className={styler.get("feedHeading")}>
                                        {heading}
                                      </h4>
                                      <SanityRichText
                                        className={styler.get(
                                          "feedDescription"
                                        )}
                                        blocks={_rawDesc}
                                      />
                                    </div>
                                  </OutboundLink>
                                )
                              )}
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div className={styler.get("left")}>
                            <h3 className={styler.get("dropHeading")}>
                              {heading}
                            </h3>
                            <p className={styler.get("dropDescription")}>
                              {desc}
                            </p>
                            <div className={styler.get("entries")}>
                              {entries.map(
                                ({alt, heading, icon, link, desc}) => {
                                  return (
                                    <OutboundLink
                                      className={styler.get("entry")}
                                      href={link}
                                    >
                                      <GatsbyImage
                                        alt={alt || ""}
                                        image={icon.asset.gatsbyImageData}
                                        className={styler.get("entryIcon")}
                                      />
                                      <div
                                        className={styler.get("entryDetails")}
                                      >
                                        <h4
                                          className={styler.get("entryHeading")}
                                        >
                                          {heading}
                                        </h4>
                                        <p
                                          className={styler.get(
                                            "entryDescription"
                                          )}
                                        >
                                          {desc}
                                        </p>
                                      </div>
                                    </OutboundLink>
                                  );
                                }
                              )}
                            </div>
                            {bottomLinks.length ? (
                              <div className={styler.get("bottomLinks")}>
                                {bottomLinks.map(({link, name}) => (
                                  <LinkComponent
                                    link={link}
                                    className={styler.get("bottomLink")}
                                  >
                                    {name}
                                  </LinkComponent>
                                ))}
                              </div>
                            ) : (
                              <span />
                            )}
                          </div>
                        </>
                      )}
                      {shouldShowDemoSection && (
                        <OutboundLink
                          href={navUrl}
                          rel='noopener noreferrer'
                          className={styler.get("demoSection")}
                        >
                          <ArrowCursor /> {navText}
                        </OutboundLink>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};
