import * as React from "react";

export const ScrollDirection = {
  UP: "UP",
  DOWN: "DOWN",
};

const getPercentage = (num, denom) => Math.round((num / denom) * 1000) / 10;

// returns
// - percentage of page scrolled up to 1 decimal place
// - function to get scroll percentage of custom scroll height
export const useScrollPercentage = () => {
  const [scrollPercentage, setScrollPercentage] = React.useState(0);

  // setting current scroll percentage
  React.useEffect(() => {
    const winScroll = window.pageYOffset;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    setScrollPercentage(getPercentage(winScroll, height));
  }, []);

  // updating scroll percentage on scroll
  React.useEffect(() => {
    const updateScrollPercentage = () => {
      const winScroll = window.pageYOffset;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;

      // rounding to 1 decimal place
      setScrollPercentage(getPercentage(winScroll, height));
    };

    window.addEventListener("scroll", updateScrollPercentage);

    return () => window.removeEventListener("scroll", updateScrollPercentage);
  }, [scrollPercentage]);

  // to get percentage of a custom scroll height wrt page height
  const getScrollPercentage = (scrollHeight) => {
    const pageHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    return getPercentage(scrollHeight, pageHeight);
  };

  return {scrollPercentage, getScrollPercentage};
};
