/* eslint-disable eqeqeq */
import {StyleExtractor, get} from "@kubric/utils";
import {useLocation} from "@reach/router";
import {graphql, useStaticQuery} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import React, {useState} from "react";

import {useMediaQuery} from "../../hooks/useMediaQuery";
import {useScrollPercentage} from "../../utils/scrollPercentage";
import MMCta from "../commons/MMCTA";
import {MenuItems} from "./components";
import * as styles from "./styles.module.scss";

const HEADER_QUERY = graphql`
  {
    allSanityHeaderSection {
      nodes {
        menuItems {
          _key
          name
          link
          hasSubMenu
          heading
          desc
          entries {
            desc
            heading
            link
            _key
            icon {
              asset {
                gatsbyImageData
              }
            }
            alt
            showNewTag
          }
          feed {
            cta
            heading
            icon {
              asset {
                gatsbyImageData
              }
            }
            alt
            link
            _rawDesc
            _key
          }
          layout
          bottomLinks {
            link
            name
            _key
          }
        }
        mmCTA {
          text
          url
        }
        mmCTA2 {
          text
          url
        }
        masonCTA {
          text
          url
        }
      }
    }
    allSanityProductWalkthroughPage {
      nodes {
        navText
        navUrl
      }
    }
  }
`;

const Header = ({
  type,
  showHeaderShadow = false,
  alternateBg = false,
  mmv3,
  banner = {},
  showMMCta = false,
  noHeader = false,
  hideHamMenuOnMobile = false,
  hideHeaderBackground = false,
  darkMode = false,
  noCTAHeader = false,
  masonLogo = false,
}) => {
  const data = useStaticQuery(HEADER_QUERY);
  const styler = new StyleExtractor(styles);
  const isMobile = useMediaQuery("(max-width: 900px)");
  const [isMenuOpen, setMenuState] = useState(false);
  const menuItems = get(data, "allSanityHeaderSection.nodes.0.menuItems");
  const demoSection = get(data, "allSanityProductWalkthroughPage.nodes.0");
  const mmCTA = get(data, "allSanityHeaderSection.nodes.0.mmCTA");
  const mmCTA2 = get(data, "allSanityHeaderSection.nodes.0.mmCTA2");
  const masonCTA = get(data, "allSanityHeaderSection.nodes.0.masonCTA");
  const location = useLocation().pathname;
  const isBlogPost = location.includes("/blog/post/");
  const {showMMV3Banner, cta, image, mImage, show, text, url, ctaID} = banner;
  const {scrollPercentage} = useScrollPercentage();

  // show scroll progress line in blog posts and when menu is not open
  const showScrollProgress = !isMenuOpen && isBlogPost;

  // showing intercom option as nav option
  // and hiding its default bubble on bottom right
  // only when its modemagic3 page and mobile view
  React.useEffect(() => {
    if (typeof window.Intercom === "function") {
      if (mmv3 && isMobile) {
        window.Intercom("update", {
          hide_default_launcher: true,
        });
      } else {
        window.Intercom("update", {
          hide_default_launcher: false,
        });
      }
    }
  }, [mmv3, isMobile]);

  return (
    <>
      <header
        className={`${styler.get([
          "header",
          alternateBg && "addBackground",
          showScrollProgress && "scrollProgressActive",
          type === "modemagic" && "mm",
          mmv3 && "mmv3",
          showHeaderShadow && "addBoxShadow",
          hideHeaderBackground && "transparentBackground",
          darkMode && "darkMode",
        ])} header full-bleed layouted`}
      >
        <div className={styler.get("headerCol")}>
          <OutboundLink
            href='/'
            className={styler.get(`${hideHamMenuOnMobile ? "centerLogo" : ""}`)}
          >
            <div className={styler.get("logoContainer")}>
              {masonLogo ? (
                <div className={styler.get("masonLogo")}>
                  <StaticImage
                    loading='lazy'
                    src='https://media.kubric.io/api/assetlib/2dbb965e-e010-4e07-aeca-088b140616d5.webp'
                    alt='logo mason'
                  />
                  &ensp;{" "}
                  <span
                    className={styler.get([
                      "logoText",
                      darkMode && "darkModeText",
                    ])}
                  >
                    mason
                  </span>
                </div>
              ) : (
                <>
                  {type === "modemagic" ? (
                    mmv3 ? (
                      <div className={styler.get("modemagicLogo")}>
                        <StaticImage
                          loading='lazy'
                          src='https://media.kubric.io/api/assetlib/5a4b3d2a-2ae2-4ef7-bde8-cd7bb5f3d829.png'
                          alt='logo modemagic'
                          height={45}
                        />
                      </div>
                    ) : (
                      <div className={styler.get("modemagicLogo")}>
                        <StaticImage
                          loading='lazy'
                          src='https://media.kubric.io/api/assetlib/f501b79c-9b97-4c38-9c2b-4ea6dc1f5775.webp'
                          alt='logo modemagic'
                        />
                      </div>
                    )
                  ) : (
                    <div className={styler.get("masonLogo")}>
                      <StaticImage
                        loading='lazy'
                        src='https://media.kubric.io/api/assetlib/2dbb965e-e010-4e07-aeca-088b140616d5.webp'
                        alt='logo mason'
                      />
                      &ensp;{" "}
                      <span
                        className={styler.get([
                          "logoText",
                          darkMode && "darkModeText",
                        ])}
                      >
                        mason
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
          </OutboundLink>
          {isMobile && !noHeader && !hideHamMenuOnMobile && (
            <div
              className={styler.get(["hamMenu", isMenuOpen && "hamActive"])}
              onClick={() => setMenuState(!isMenuOpen)}
            >
              {Array(3)
                .fill(null)
                .map((_, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className={styler.get(["hamLine", darkMode && 'darkModeLine'])} key={`hamLine-${i}`} />
                ))}
            </div>
          )}
          <div
            className={styler.get([
              "rightContainer",
              darkMode && "darkModeContainer",
              isMobile ? isMenuOpen && "show" : "show",
            ])}
          >
            <MenuItems
              menuItems={menuItems}
              showIntercom={mmv3 && isMobile}
              noHeader={noHeader}
              demoSection={demoSection}
              darkMode={darkMode}
            />
            {noCTAHeader ? null : (
              <>
                {type === "mason" && !showMMCta ? (
                  <a
                    className={styler.get(
                      ["buttonMargin", !isMobile && "signUpButton"],
                      [isMobile && "pbuttonWrap"]
                    )}
                    href={masonCTA?.url || "https://app.getmason.io/login"}
                    rel='noopener noreferrer'
                    id='get-mason-app'
                  >
                    {isMobile
                      ? masonCTA?.text || "Get Started for Free"
                      : "Sign up"}
                  </a>
                ) : (
                  <MMCta
                    footer={false}
                    id='mm-install-app-topnav'
                    theme={{
                      container: styler.get("mmCtaContainer"),
                      cta: styler.get("mmCta"),
                    }}
                    hideEmailInMobile
                    href={mmCTA?.url}
                    cta={mmCTA?.text || "Get Started"}
                  />
                )}
                <div className={styler.get("mmCtaContainer")}>
                  <OutboundLink
                    href={mmCTA2?.url}
                    target='_blank'
                    rel='noreferrer'
                    className={styler.get("mmCta2")}
                  >
                    {mmCTA2?.text}
                  </OutboundLink>
                </div>
              </>
            )}
          </div>
        </div>
        {showScrollProgress && (
          <hr
            className={styler.get("scrollProgressLine")}
            style={{width: `${scrollPercentage}%`}}
          />
        )}
        {showMMV3Banner && (
          <div className={styler.get("bannerContainer")}>
            <div className={styler.get("content")}>
              <div className={styler.get("text")}>{text}</div>
              <OutboundLink
                className={styler.get("cta")}
                href={url}
                target='_blank'
                {...(ctaID ? {id: ctaID} : {})}
              >
                {cta}
              </OutboundLink>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
