import {
  StyleExtractor,
  isFunction,
  isString,
  isValidString,
} from "@kubric/utils";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import React, {useRef, useState} from "react";
import Loader from "react-feather/dist/icons/loader";

import actions from "../../../lib/actions";
import {EmailIcon} from "../../icons/Email";
import * as styles from "./styles.module.scss";

export const isInvalidEmail = (email) =>
  !String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

const EmailCTA = ({
  id,
  data = {},
  style,
  theme,
  children,
  propOnClick,
  showResponse = false,
  showLoading = false,
  tags = [],
  overrideTags = false,
  customClickHandler,
  alternateResponse = false,
  source = "MasonPage",
  onResponseVisible = () => {},
  onResponseCTAClick = () => {},
}) => {
  const {
    action,
    bgColor: background,
    borderColor,
    cta,
    textColor: color,
    placeholder = "Enter email",
    response = "🙌🏻 Thanks! We got you.",
    responseRedirect = false,
    email = "",
  } = data;
  const inputRef = useRef();
  const styler = new StyleExtractor(styles, theme);
  const [erred, setErred] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const actionFn = actions[action];
  const [value, setValue] = React.useState(email);

  React.useEffect(() => {
    if (email && isString(email)) {
      setValue(email);
    }
  }, [email]);

  React.useEffect(() => {
    if (submitted && showResponse) {
      onResponseVisible();
    }
  }, [submitted && showResponse]);

  const onClick = (e) => {
    e.preventDefault();
    if (isFunction(customClickHandler)) {
      const value = inputRef.current.value;
      if (!isValidString(value) || isInvalidEmail(value)) {
        setErred(true);
        inputRef.current.focus();
      } else {
        setErred(false);
        setLoading(true);
        customClickHandler(value)
          ?.catch(() => {
            setLoading(false);
          })
          ?.finally(() => {
            setLoading(false);
            setSubmitted(true);
          });
      }
    } else {
      const clickFn = isFunction(propOnClick) ? propOnClick : actionFn;
      const value = inputRef.current.value;
      if (!isValidString(value) || isInvalidEmail(value)) {
        setErred(true);
        inputRef.current.focus();
      } else {
        setErred(false);
        setLoading(true);
        clickFn({
          email: value,
          tags,
          overrideTags,
          hubSpotData: {
            email: value,
            source,
            sourceUrl: window.location.href,
          },
        })
          .catch(() => {
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
            setSubmitted(true);
          });
      }
    }
  };

  if (submitted && showResponse) {
    return alternateResponse ? (
      <div className={styler.get("altResponseContainer")}>
        <EmailIcon className={styler.get("emailIcon")} />
        <div className={styler.get("title")}>Thank you!</div>
        <div className={styler.get("description")}>
          We’ll be in touch shortly.
        </div>
        <div
          className={styler.get("btn")}
          onClick={() => {
            if (responseRedirect) {
              let newWindow = window.open(responseRedirect, "_blank");
              newWindow.opener = null;
            }
            setSubmitted(false);
            setLoading(false);
            setErred(false);
            setValue("");
            onResponseCTAClick();
          }}
        >
          Continue
        </div>
      </div>
    ) : (
      <div
        className={styler.get("responseContainer", [
          responseRedirect && "cursor-pointer",
        ])}
        onClick={() => {
          if (responseRedirect) {
            let newWindow = window.open(responseRedirect, "_blank");
            newWindow.opener = null;
          }
        }}
      >
        <p className={styler.get("response")}>{response}</p>
      </div>
    );
  }

  return (
    <div
      className={styler.get(["container", erred && "erred"])}
      style={{borderColor}}
    >
      <input
        type='email'
        className={styles.email}
        placeholder={placeholder}
        ref={inputRef}
        disabled={loading || submitted}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {loading && showLoading ? (
        <div className={styler.get("cta")}>
          <Loader width={24} className={styler.get("loader")} />
        </div>
      ) : (
        <OutboundLink
          onClick={onClick}
          id={id || "get-mason-app"}
          className={styler.get("cta")}
          style={{
            background,
            borderColor,
            color,
            ...style,
          }}
        >
          {isValidString(cta) ? cta : children}
        </OutboundLink>
      )}
    </div>
  );
};
export default EmailCTA;
