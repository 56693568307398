// extracted by mini-css-extract-plugin
export var auditCTAContainer = "styles-module--auditCTAContainer--22lFU";
export var auditCTAText = "styles-module--auditCTAText--2DGRv";
export var container = "styles-module--container--2g6x8";
export var cta = "styles-module--cta---8HFi";
export var ctaContainer = "styles-module--ctaContainer--tX6pH";
export var divider = "styles-module--divider--57TAg";
export var heroCTA = "styles-module--heroCTA--1YIKG";
export var highlightedAuditCTA = "styles-module--highlightedAuditCTA--15VBa";
export var mmCtaFooter = "styles-module--mmCtaFooter--1sMA9";
export var mobileAuditCTA = "styles-module--mobileAuditCTA--muriQ";
export var phuntImage = "styles-module--phuntImage--v01eE";
export var shopCommAwardsDiv = "styles-module--shopCommAwardsDiv--sR-M9";
export var shopCommAwardsDivHighlighted = "styles-module--shopCommAwardsDivHighlighted--2CX2t";
export var shopifyImage = "styles-module--shopifyImage--26nfk";
export var techCrunch = "styles-module--techCrunch--17ztA";
export var v3 = "styles-module--v3--1cQlN";