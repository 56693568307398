import "regenerator-runtime/runtime";

const axios = require("axios").default;

// to capture emails in hubSpot for marketing analysis
export const hubSpot = async ({data, redirect}) => {
  try {
    await axios
      .post("/api/hubSpot", {
        ...data,
        env: process.env.GATSBY_ENV,
      })
      .catch((e) => {
        // console.debug(e);
      })
      .finally(() => {
        if (redirect) {
          window.location.href = redirect;
        }
      });
  } catch (e) {
    // console.debug(e);
  }
};
