import React from "react";

import * as styles from "./styles.module.scss";

export const ShopifyIcon = ({className, onClick, hovered}) => {
  return (
    <svg
      width='23'
      height='24'
      viewBox='0 0 23 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <g clipPath='url(#clip0_1159_4065)'>
        <path
          d='M20.0275 4.71281C20.0103 4.59281 19.9012 4.5264 19.811 4.51909C19.721 4.51186 17.8159 4.37583 17.8159 4.37583C17.8159 4.37583 16.4928 3.11049 16.3475 2.97043C16.2022 2.83046 15.9185 2.87303 15.8083 2.90427C15.7921 2.90887 15.5192 2.98999 15.0679 3.12454C14.6259 1.89939 13.8459 0.773527 12.4736 0.773527C12.4357 0.773527 12.3967 0.775006 12.3577 0.777143C11.9675 0.279965 11.484 0.0639648 11.0665 0.0639648C7.86982 0.0639648 6.34262 3.91342 5.86379 5.86958C4.62165 6.24035 3.73924 6.50394 3.62652 6.53805C2.93319 6.74755 2.91126 6.7686 2.82022 7.39794C2.7517 7.8744 0.937561 21.3891 0.937561 21.3891L15.0738 23.9405L22.7332 22.3444C22.7332 22.3444 20.0444 4.83282 20.0275 4.71281H20.0275ZM14.2866 3.35731L13.0904 3.71394C13.0908 3.63273 13.0913 3.55284 13.0913 3.46547C13.0913 2.70405 12.9815 2.09098 12.8055 1.60498C13.5126 1.69046 13.9835 2.46544 14.2866 3.35731ZM11.9284 1.75597C12.125 2.23046 12.2528 2.91142 12.2528 3.83032C12.2528 3.87734 12.2524 3.92032 12.2519 3.9638C11.474 4.19591 10.6288 4.44791 9.78158 4.70073C10.2573 2.93229 11.1489 2.07816 11.9284 1.75597ZM10.9787 0.88991C11.1166 0.88991 11.2556 0.935033 11.3886 1.02323C10.3642 1.48761 9.26605 2.6572 8.80232 4.99284L6.84961 5.57542C7.39278 3.79391 8.68253 0.88991 10.9786 0.88991H10.9787Z'
          fill={hovered ? "#9FBD58" : "#BBC1CC"}
          id={styles.bagFront}
        />
        <path
          d='M19.811 4.51911C19.721 4.51188 17.8159 4.37585 17.8159 4.37585C17.8159 4.37585 16.4928 3.11051 16.3476 2.97046C16.2932 2.91835 16.2199 2.89163 16.1433 2.88013L15.0745 23.9404L22.7333 22.3444C22.7333 22.3444 20.0444 4.83284 20.0275 4.71284C20.0103 4.59284 19.9012 4.52643 19.811 4.51911Z'
          fill={hovered ? "#698D48" : "#808693"}
          id={styles.bagSide}
        />
        <path
          d='M12.4737 8.59605L11.5292 11.3023C11.5292 11.3023 10.7017 10.8769 9.68742 10.8769C8.20041 10.8769 8.12558 11.7758 8.12558 12.0023C8.12558 13.2383 11.4703 13.7119 11.4703 16.607C11.4703 18.8848 9.97052 20.3515 7.94836 20.3515C5.52177 20.3515 4.28082 18.8967 4.28082 18.8967L4.93056 16.8288C4.93056 16.8288 6.20614 17.8837 7.2825 17.8837C7.98582 17.8837 8.27191 17.3503 8.27191 16.9605C8.27191 15.3483 5.52791 15.2764 5.52791 12.6271C5.52791 10.3973 7.18932 8.2395 10.543 8.2395C11.8353 8.2395 12.4737 8.59605 12.4737 8.59605Z'
          fill='white'
          id={styles.onBag}
        />
      </g>
      <defs>
        <clipPath id='clip0_1159_4065'>
          <rect
            width='21.8428'
            height='24'
            fill='white'
            transform='translate(0.934326)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ShopifyStars = ({className, color = "#BBC1CC"}) => (
  <svg
    width='100'
    height='13'
    viewBox='0 0 100 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    color={color}
  >
    <path
      d='M13.4319 4.71384L9.30803 4.16155L7.46457 0.717594C7.41422 0.6233 7.33139 0.546968 7.22906 0.500569C6.97244 0.383825 6.66059 0.481112 6.53228 0.717594L4.68882 4.16155L0.56498 4.71384C0.451286 4.72881 0.347338 4.7782 0.267752 4.85303C0.171537 4.94417 0.118519 5.06677 0.120346 5.1939C0.122173 5.32104 0.178697 5.4423 0.277497 5.53105L3.26115 8.21168L2.55625 11.9969C2.53972 12.0849 2.55029 12.1755 2.58677 12.2583C2.62325 12.3411 2.68417 12.4128 2.76263 12.4653C2.84109 12.5178 2.93395 12.549 3.03067 12.5554C3.1274 12.5618 3.22412 12.543 3.30987 12.5013L6.99842 10.7142L10.687 12.5013C10.7877 12.5507 10.9046 12.5671 11.0167 12.5492C11.2993 12.5043 11.4893 12.2573 11.4406 11.9969L10.7357 8.21168L13.7194 5.53105C13.8006 5.45771 13.8542 5.36192 13.8704 5.25715C13.9143 4.99522 13.7161 4.75276 13.4319 4.71384Z'
      fill='currentColor'
    />
    <path
      d='M56.4936 4.71384L52.3698 4.16155L50.5263 0.717594C50.476 0.6233 50.3932 0.546968 50.2908 0.500569C50.0342 0.383825 49.7224 0.481112 49.594 0.717594L47.7506 4.16155L43.6267 4.71384C43.5131 4.72881 43.4091 4.7782 43.3295 4.85303C43.2333 4.94417 43.1803 5.06677 43.1821 5.1939C43.1839 5.32104 43.2405 5.4423 43.3393 5.53105L46.3229 8.21168L45.618 11.9969C45.6015 12.0849 45.6121 12.1755 45.6485 12.2583C45.685 12.3411 45.7459 12.4128 45.8244 12.4653C45.9029 12.5178 45.9957 12.549 46.0924 12.5554C46.1892 12.5618 46.2859 12.543 46.3716 12.5013L50.0602 10.7142L53.7487 12.5013C53.8494 12.5507 53.9664 12.5671 54.0785 12.5492C54.3611 12.5043 54.5511 12.2573 54.5024 11.9969L53.7975 8.21168L56.7811 5.53105C56.8623 5.45771 56.9159 5.36192 56.9322 5.25715C56.976 4.99522 56.7779 4.75276 56.4936 4.71384Z'
      fill='currentColor'
    />
    <path
      d='M34.9628 4.71384L30.8389 4.16155L28.9955 0.717594C28.9451 0.6233 28.8623 0.546968 28.7599 0.500569C28.5033 0.383825 28.1915 0.481112 28.0632 0.717594L26.2197 4.16155L22.0959 4.71384C21.9822 4.72881 21.8782 4.7782 21.7986 4.85303C21.7024 4.94417 21.6494 5.06677 21.6512 5.1939C21.6531 5.32104 21.7096 5.4423 21.8084 5.53105L24.792 8.21168L24.0871 11.9969C24.0706 12.0849 24.0812 12.1755 24.1177 12.2583C24.1541 12.3411 24.2151 12.4128 24.2935 12.4653C24.372 12.5178 24.4648 12.549 24.5616 12.5554C24.6583 12.5618 24.755 12.543 24.8408 12.5013L28.5293 10.7142L32.2179 12.5013C32.3186 12.5507 32.4355 12.5671 32.5476 12.5492C32.8302 12.5043 33.0202 12.2573 32.9715 11.9969L32.2666 8.21168L35.2502 5.53105C35.3314 5.45771 35.385 5.36192 35.4013 5.25715C35.4451 4.99522 35.247 4.75276 34.9628 4.71384Z'
      fill='currentColor'
    />
    <path
      d='M78.0246 4.71384L73.9007 4.16155L72.0573 0.717594C72.0069 0.6233 71.9241 0.546968 71.8218 0.500569C71.5652 0.383825 71.2533 0.481112 71.125 0.717594L69.2815 4.16155L65.1577 4.71384C65.044 4.72881 64.9401 4.7782 64.8605 4.85303C64.7642 4.94417 64.7112 5.06677 64.7131 5.1939C64.7149 5.32104 64.7714 5.4423 64.8702 5.53105L67.8539 8.21168L67.149 11.9969C67.1324 12.0849 67.143 12.1755 67.1795 12.2583C67.216 12.3411 67.2769 12.4128 67.3553 12.4653C67.4338 12.5178 67.5267 12.549 67.6234 12.5554C67.7201 12.5618 67.8168 12.543 67.9026 12.5013L71.5911 10.7142L75.2797 12.5013C75.3804 12.5507 75.4973 12.5671 75.6094 12.5492C75.892 12.5043 76.082 12.2573 76.0333 11.9969L75.3284 8.21168L78.3121 5.53105C78.3933 5.45771 78.4469 5.36192 78.4631 5.25715C78.507 4.99522 78.3088 4.75276 78.0246 4.71384Z'
      fill='currentColor'
    />
    <path
      d='M99.5554 4.71384L95.4316 4.16155L93.5881 0.717594C93.5378 0.6233 93.4549 0.546968 93.3526 0.500569C93.096 0.383825 92.7841 0.481112 92.6558 0.717594L90.8124 4.16155L86.6885 4.71384C86.5748 4.72881 86.4709 4.7782 86.3913 4.85303C86.2951 4.94417 86.2421 5.06677 86.2439 5.1939C86.2457 5.32104 86.3022 5.4423 86.401 5.53105L89.3847 8.21168L88.6798 11.9969C88.6633 12.0849 88.6738 12.1755 88.7103 12.2583C88.7468 12.3411 88.8077 12.4128 88.8862 12.4653C88.9646 12.5178 89.0575 12.549 89.1542 12.5554C89.2509 12.5618 89.3477 12.543 89.4334 12.5013L93.122 10.7142L96.8105 12.5013C96.9112 12.5507 97.0282 12.5671 97.1402 12.5492C97.4228 12.5043 97.6129 12.2573 97.5641 11.9969L96.8592 8.21168L99.8429 5.53105C99.9241 5.45771 99.9777 5.36192 99.9939 5.25715C100.038 4.99522 99.8396 4.75276 99.5554 4.71384Z'
      fill='currentColor'
    />
  </svg>
);

export const ShopifyReviewsIcon = ({className, onClick, hovered}) => {
  return (
    <div
      className={`${styles.shopifyReviewsContainer}${
        hovered ? ` ${styles.hovered}` : ""
      }${className ? ` ${className}` : ""}`}
      onClick={onClick}
    >
      <div className={styles.top}>
        <ShopifyIcon className={styles.shopifyIcon} hovered={hovered} />
        <b>Shopify</b>
      </div>
      <div className={styles.middle}>
        <span>500+&nbsp;&nbsp;User Reviews</span>
      </div>
      <div className={styles.bottom}>
        <span>4.9</span> <ShopifyStars className={styles.stars} />
      </div>
    </div>
  );
};
