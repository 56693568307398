import {isValidString} from "@kubric/utils";
import axios from "axios";

import {hubSpot} from "../utils/hubSpot";

const joinList = (data) => {
  const {shouldSendMarketingEmails = false, onlineExperience} = data;
  const tags = [
    {
      name: "Send Marketing Emails",
      status: shouldSendMarketingEmails ? "active" : "inactive",
    },
  ];
  if (isValidString(onlineExperience)) {
    tags.push({
      name: onlineExperience,
      status: "active",
    });
  }
  return axios.post("/api/joinlist", {
    ...data,
    list: "makerslist",
    tags,
    env: process.env.GATSBY_ENV,
  });
};

const subscribeFutureEvents = (data) => {
  axios.post("/api/joinlist", {
    ...data,
    list: "makerslist",
    env: process.env.GATSBY_ENV,
  });
};

const mmGetStarted = ({tags, overrideTags = false, hubSpotData, ...data}) =>
  axios
    .post("/api/joinlist", {
      ...data,
      list: "modemagic",
      label: "modemagic",
      tags: [
        ...(overrideTags ? [] : [{name: "Ads lead", status: "active"}]),
        ...tags.map((tag) => ({name: tag, status: "active"})),
      ],
      env: process.env.GATSBY_ENV,
    })
    .finally(() => hubSpot({data: hubSpotData}));

const mmGetStartedWithASRedirect = (data) =>
  mmGetStarted(data).finally(() => {
    window.location.href =
      "https://apps.shopify.com/store-conversions-modemagic?utm_source=modemagic-page&utm_medium=website";
  });

const mmInstallApp = () => {
  window.location.href =
    "https://www.shopify.com/admin/oauth/authorize?client_id=70a074b87b07f9468ceafd018c360396&scope=write_products%2Cwrite_script_tags%2Cwrite_themes%2Cread_discounts%2Cread_orders%2Cread_inventory%2Cread_price_rules&redirect_uri=https%3A%2F%2Fapp.getmodemagic.com%2Finstall%2Fcallback";
};

const masonHomeCtaFn = ({tags, hubSpotData, ...data}) =>
  axios
    .post("/api/joinlist", {
      ...data,
      list: "getmason",
      account: "mason",
      tags: [
        {name: "Mason Lead", status: "active"},
        tags.map((tag) => ({name: tag, status: "active"})),
      ],
      env: process.env.GATSBY_ENV,
    })
    .finally(() => {
      hubSpot({data: hubSpotData, redirect: "https://app.getmason.io/login"});
    });

const commonJoinList = ({tags = [], list, onDone, ...data}) =>
  axios
    .post("/api/joinlist", {
      ...data,
      list,
      tags: tags.map((tag) => ({name: tag, status: "active"})),
      env: process.env.GATSBY_ENV,
    })
    .catch(() => {
      // console.error(e);
    })
    .finally(onDone);

const scrollToForm = () => window.scrollTo(0, document.body.scrollHeight);

const scrollToTop = () => {
  window.location.hash = "top";
  setTimeout(() => {
    window.location.hash = "";
  });
};

const actions = {
  joinList,
  scrollToForm,
  mmGetStarted,
  mmGetStartedWithASRedirect,
  mmInstallApp,
  scrollToTop,
  subscribeFutureEvents,
  masonHomeCtaFn,
};

export {commonJoinList};
export default actions;
