import React from "react";

export const Calculator = ({
  className,
  onClick,
  size = 65,
  color = "#F5C352",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 65 65'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      color={color}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 5.95082C10 4.32113 11.286 3 12.8723 3H52.1277C53.714 3 55 4.32113 55 5.95082V60.0492C55 61.6789 53.714 63 52.1277 63H12.8723C11.286 63 10 61.6789 10 60.0492V5.95082ZM15.7447 8.90164V57.0984H49.2553V8.90164H15.7447Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M52.1274 24.6394H12.8721V18.7378H52.1274V24.6394Z'
        fill='currentColor'
      />
      <path
        d='M24.3613 31.5247H30.106V37.4263H24.3613V31.5247Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.9253 31.5245C22.9253 30.7096 23.5683 30.0491 24.3615 30.0491H30.1061C30.8993 30.0491 31.5423 30.7096 31.5423 31.5245V37.4261C31.5423 38.241 30.8993 38.9015 30.1061 38.9015H24.3615C23.5683 38.9015 22.9253 38.241 22.9253 37.4261V31.5245ZM25.7976 32.9999V35.9507H28.67V32.9999H25.7976Z'
        fill='currentColor'
      />
      <path
        d='M24.3613 43.3279H30.106V49.2295H24.3613V43.3279Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.9253 43.3279C22.9253 42.5131 23.5683 41.8525 24.3615 41.8525H30.1061C30.8993 41.8525 31.5423 42.5131 31.5423 43.3279V49.2296C31.5423 50.0444 30.8993 50.705 30.1061 50.705H24.3615C23.5683 50.705 22.9253 50.0444 22.9253 49.2296V43.3279ZM25.7976 44.8034V47.7542H28.67V44.8034H25.7976Z'
        fill='currentColor'
      />
      <path
        d='M36.8086 31.5247H41.5958V37.4263H36.8086V31.5247Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.3721 31.5245C35.3721 30.7096 36.0151 30.0491 36.8082 30.0491H41.5955C42.3886 30.0491 43.0316 30.7096 43.0316 31.5245V37.4261C43.0316 38.241 42.3886 38.9015 41.5955 38.9015H36.8082C36.0151 38.9015 35.3721 38.241 35.3721 37.4261V31.5245ZM38.2444 32.9999V35.9507H40.1593V32.9999H38.2444Z'
        fill='currentColor'
      />
      <path
        d='M36.8086 43.3279H41.5958V49.2295H36.8086V43.3279Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.3721 43.3279C35.3721 42.5131 36.0151 41.8525 36.8082 41.8525H41.5955C42.3886 41.8525 43.0316 42.5131 43.0316 43.3279V49.2296C43.0316 50.0444 42.3886 50.705 41.5955 50.705H36.8082C36.0151 50.705 35.3721 50.0444 35.3721 49.2296V43.3279ZM38.2444 44.8034V47.7542H40.1593V44.8034H38.2444Z'
        fill='currentColor'
      />
    </svg>
  );
};
