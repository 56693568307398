// extracted by mini-css-extract-plugin
export var active = "styles-module--active--1EEj9";
export var bagFront = "styles-module--bagFront--WnYKw";
export var bagSide = "styles-module--bagSide--1_U-G";
export var bottom = "styles-module--bottom--f0Jqw";
export var facebookIcon = "styles-module--facebookIcon--LN4oT";
export var firstLadder = "styles-module--firstLadder--1lfRn";
export var firstLadderShadow = "styles-module--firstLadderShadow--3nSoU";
export var hovered = "styles-module--hovered--2aKil";
export var instagramIcon = "styles-module--instagramIcon--RCqxg";
export var kitty = "styles-module--kitty--1KGwg";
export var kittyIcon = "styles-module--kittyIcon--1IaC5";
export var kittyShadow = "styles-module--kittyShadow--mXu5h";
export var linkedinIcon = "styles-module--linkedinIcon--kuf70";
export var middle = "styles-module--middle--9J7_V";
export var mouth = "styles-module--mouth--ggC7l";
export var onBag = "styles-module--onBag--2ahqT";
export var plain = "styles-module--plain--1slz8";
export var secondLadder = "styles-module--secondLadder--3-hPN";
export var shopifyIcon = "styles-module--shopifyIcon--3rSuL";
export var shopifyReviewsContainer = "styles-module--shopifyReviewsContainer--13JBV";
export var stars = "styles-module--stars--2z8DQ";
export var text = "styles-module--text--1hlRL";
export var thirdLadder = "styles-module--thirdLadder--1do_j";
export var top = "styles-module--top--1iJRK";
export var twitterIcon = "styles-module--twitterIcon--18UnD";
export var youtubeIcon = "styles-module--youtubeIcon--1NJY2";