// extracted by mini-css-extract-plugin
export var altResponseContainer = "styles-module--altResponseContainer--y6C1O";
export var btn = "styles-module--btn--3Atm0";
export var container = "styles-module--container--2uPAP";
export var cta = "styles-module--cta--1ZIf7";
export var description = "styles-module--description--33fF_";
export var email = "styles-module--email--10736";
export var emailIcon = "styles-module--emailIcon--3N1Zi";
export var erred = "styles-module--erred--3drCl";
export var loader = "styles-module--loader--35GkL";
export var response = "styles-module--response--1wgCX";
export var responseContainer = "styles-module--responseContainer--3G0uj";
export var spin = "styles-module--spin--3lySH";
export var title = "styles-module--title--LJmie";