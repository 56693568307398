import {useEffect, useRef} from "react";

import {MMTrackFrameId, getAppLink} from "../lib/constants";

const messageHandler = (e) => {
  const {origin, data: {type, data} = {}} = e;
  // If the message is received from the MM track iframe
  if (origin === process.env.MM_URL) {
    // If the MM websitelogin service has been fired correctly
    if (type === "getMMStarted_response") {
      // Open the link to install the app
      window.open(getAppLink(data));
      //  Else if erred
    } else if (type === "getMMStarted_error") {
      // Log the error
      console.error("Get started click failed");
      console.error(data);
    }
  }
};

export const useAppLogin = () => {
  const isAttachedRef = useRef(false);
  useEffect(() => {
    if (typeof window !== "undefined" && !isAttachedRef.current) {
      window.addEventListener("message", messageHandler);
      isAttachedRef.current = true;
    }
  }, []);

  return () => {
    // If the hook is initialized in the browser
    if (typeof window !== "undefined") {
      // Getting the MM track frame window
      const frameWindow = document.querySelector(
        `#${MMTrackFrameId}`
      )?.contentWindow;
      // If the frame exists
      if (typeof frameWindow !== "undefined") {
        // Post a message to the frame that the get MM installation process started
        frameWindow.postMessage(
          {
            type: "getMMStarted",
          },
          process.env.MM_URL
        );
      }
    }
  };
};
